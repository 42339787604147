@import "../_settings.scss";
@import "../functions/_util.scss";
@import "../functions/_prefixer.scss";

.err-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.err-txt {
    font-size: rem-calc(36px);
    font-weight: bold;
}

.err-reasons {
    text-align: center;
    font-size: rem-calc(16px);
}

.err-reasons-title {
    font-size: rem-calc(24px);
}

.err-reasons-list {
    list-style: none;
    padding: 0;
    font-size: 21px;
    margin-bottom: 35px;
}


.err-code {
    color: black;
    font-size: rem-calc(140px);
    position: relative;
    /*width: 330px;*/
    text-align: center;
    line-height: 1;
    margin-top: -40px;
}

.err-image {
    margin: 0 auto;
    display: block;
}

@media(min-width: 48em) {
    .err-code {
        margin-top: -30px;
        font-size: rem-calc(192px);
    }
}

/*.err-code:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: black;
    background: white;
    overflow: hidden;
    clip: rect(0,900px,0,0);
    -webkit-animation: noise-anim 2s infinite linear alternate-reverse;
    -moz-animation: noise-anim 2s infinite linear alternate-reverse;
    -o-animation: noise-anim 2s infinite linear alternate-reverse;
    animation: noise-anim 2s infinite linear alternate-reverse;
}

.err-code:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: black;
    background: white;
    overflow: hidden;
    clip: rect(0,900px,0,0);
    -webkit-animation: noise-anim-2 3s infinite linear alternate-reverse;
    -moz-animation: noise-anim-2 3s infinite linear alternate-reverse;
    -o-animation: noise-anim-2 3s infinite linear alternate-reverse;
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}

@include keyframes(noise-anim) {
    0% {
        clip: rect(131px, 9999px, 1px, 0);
    }

    5% {
        clip: rect(191px, 9999px, 109px, 0);
    }

    10% {
        clip: rect(91px, 9999px, 50px, 0);
    }

    15.0% {
        clip: rect(82px, 9999px, 40px, 0);
    }

    20% {
        clip: rect(10px, 9999px, 194px, 0);
    }

    25% {
        clip: rect(60px, 9999px, 100px, 0);
    }

    30.0% {
        clip: rect(73px, 9999px, 82px, 0);
    }

    35% {
        clip: rect(119px, 9999px, 40px, 0);
    }

    40% {
        clip: rect(165px, 9999px, 91px, 0);
    }

    45% {
        clip: rect(23px, 9999px, 62px, 0);
    }

    50% {
        clip: rect(55px, 9999px, 180px, 0);
    }

    55.0% {
        clip: rect(50px, 9999px, 101px, 0);
    }

    60.0% {
        clip: rect(76px, 9999px, 119px, 0);
    }

    65% {
        clip: rect(98px, 9999px, 60px, 0);
    }

    70% {
        clip: rect(144px, 9999px, 164px, 0);
    }

    75% {
        clip: rect(115px, 9999px, 55px, 0);
    }

    80% {
        clip: rect(169px, 9999px, 82px, 0);
    }

    85.0% {
        clip: rect(32px, 9999px, 164px, 0);
    }

    90% {
        clip: rect(167px, 9999px, 123px, 0);
    }

    95% {
        clip: rect(161px, 9999px, 32px, 0);
    }

    100% {
        clip: rect(153px, 9999px, 14px, 0);
    }
}

@include keyframes(noise-anim-2) {
    0% {
        clip: rect(183px, 9999px, 11px, 0);
    }

    5% {
        clip: rect(42px, 9999px, 166px, 0);
    }

    10% {
        clip: rect(112px, 9999px, 53px, 0);
    }

    15.0% {
        clip: rect(171px, 9999px, 171px, 0);
    }

    20% {
        clip: rect(185px, 9999px, 20px, 0);
    }

    25% {
        clip: rect(54px, 9999px, 88px, 0);
    }

    30.0% {
        clip: rect(16px, 9999px, 52px, 0);
    }

    35% {
        clip: rect(188px, 9999px, 168px, 0);
    }

    40% {
        clip: rect(168px, 9999px, 133px, 0);
    }

    45% {
        clip: rect(125px, 9999px, 165px, 0);
    }

    50% {
        clip: rect(88px, 9999px, 161px, 0);
    }

    55.0% {
        clip: rect(59px, 9999px, 19px, 0);
    }

    60.0% {
        clip: rect(160px, 9999px, 170px, 0);
    }

    65% {
        clip: rect(18px, 9999px, 76px, 0);
    }

    70% {
        clip: rect(36px, 9999px, 115px, 0);
    }

    75% {
        clip: rect(185px, 9999px, 86px, 0);
    }

    80% {
        clip: rect(132px, 9999px, 159px, 0);
    }

    85.0% {
        clip: rect(143px, 9999px, 190px, 0);
    }

    90% {
        clip: rect(179px, 9999px, 9px, 0);
    }

    95% {
        clip: rect(69px, 9999px, 104px, 0);
    }

    100% {
        clip: rect(69px, 9999px, 34px, 0);
    }
}*/
